import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Stack
} from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API from "../../api";
import {Helmet} from "react-helmet";

const CreateCategory = () => {
	const navigate = useNavigate();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	return (
		<Formik
			initialValues={{
				title: "",
				description: "",
				orderBy: 0,

			}}
			validate={(values) => {
				const errors = {};
				if (!values.title) {
					errors.title = "Required";
				}
				if (!values.description) {
					errors.description = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.post('admin/category', {
						title: values.title,
						description: values.description,
						orderBy: values.orderBy,
					})
						.then((res) => {
							setSubmitting(false);
							success('Successfully created category');
							navigate('/admin/category');
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
				<>
					<Helmet>
						<title>Create Category | MyGolfDNA.com</title>
						<meta name="description" content="create category for website"/>
					</Helmet>
					<Row>
						<Col md={8}>

							<Form noValidate className="mt-5 form-group" onSubmit={handleSubmit}>
								<h3>Edit Category</h3>
								<Row>
									<Col md={12}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Title"
												type="text"
												name="title"
												id="title"
												onChange={handleChange}
												value={values.title}
												isInvalid={errors.title && touched.title}
												isValid={!errors.title && touched.title}
											/>
											<Form.Label className="" htmlFor="title">Title</Form.Label>
											<div className="invalid-feedback">{errors.title}</div>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="mb-3">
									<Form.Control
										placeholder="description of category."
										as="textarea"
										rows="5"
										name="description"
										id="description"
										onChange={handleChange}
										value={values.description}
										isInvalid={errors.description && touched.description}
										isValid={!errors.description && touched.description}
									/>
									<div className="invalid-feedback">{errors.description}</div>
								</Form.Group>
								<Col md={3}>
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className=""
											placeholder="Order By"
											type="number"
											name="orderBy"
											id="orderBy"
											onChange={handleChange}
											value={values.orderBy}
											isInvalid={errors.orderBy && touched.orderBy}
											isValid={!errors.orderBy && touched.orderBy}
										/>
										<Form.Label className="" htmlFor="title">Order By</Form.Label>
										<div className="invalid-feedback">{errors.orderBy}</div>
									</Form.Group>
								</Col>
								<FormGroup className="mt-5">
									<Stack direction="horizontal" gap={3}>
										<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
											{isSubmitting ? (
												<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
												</>
											) : (
												"Submit"
											)}
										</Button>
									</Stack>
								</FormGroup>
							</Form>
						</Col>
					</Row>
				</>
			)}
		</Formik>
	);
};

export default CreateCategory;
