import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {useAuth} from "../../AuthUser";
import { useNavigate, NavLink } from "react-router-dom";
import LogoIcon from "./../../assets/img/logo2.png";
import {NavDropdown} from "react-bootstrap";
import ProfileImage from "../ProfileImage";

function LoggedInNavigation({ hideAuthButton = false }) {
	const navigate = useNavigate();
	const auth = useAuth();
	const isAdmin = auth.user.admin;

	const GetAccesNowButton = () => {
			if (!auth.user.planType) {
				return <Button className="text-uppercase font-weight-bold" variant="danger" size="" onClick={() => navigate("/pricing")}>Get Access Now</Button>;
			}
			return <Button href="/upload-videos" variant="success" disabled={!auth.user.canSubmitLesson}>Start New Lesson</Button>
	};

	const dropdown = () => {
		//return auth.user.thumbnail ? <img src={auth.user.thumbnail} width={40} height={40} className="rounded-circle" alt="avatar"/> : <i className="bi bi-person-circle h2"/>;
		return <ProfileImage size={40} textSizeRatio={2.75} text={auth.user.email}/>
	};

	return (
		<>
			<Navbar variant="dark" className="sticky-top bg-dark-blue" expand="lg">
				<Container>

					<Navbar.Brand href="/dna-profile" style={{letterSpacing: '1px'}} className="monument-font">
						MYGOLF<span className="text-secondary">DNA</span>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto main-nav">
						<Nav.Link as={NavLink} to="videos">Videos</Nav.Link>
						<Nav.Link as={NavLink} to="dna-profile">MyDNA Profile</Nav.Link>
						<Nav.Link href="https://meetings.hubspot.com/chris3535/mygolfdna">Pocket Pro</Nav.Link>
						<Nav.Link href="https://shop.mygolfdna.com/">Shop</Nav.Link>
						<Nav.Link href="https://blog.mygolfdna.com/">Articles</Nav.Link>
						<Nav.Link as={NavLink} to="support">Support</Nav.Link>
					</Nav>
						<Nav className="d-flex">
							<div className="p-2">
								<GetAccesNowButton/>
							</div>
							<NavDropdown title={dropdown()} id="navbarScrollingDropdown">
								<NavDropdown.Item href="/account">Account</NavDropdown.Item>
								{isAdmin && <NavDropdown.Item href="/admin/lessons">Admin Dashboard</NavDropdown.Item>}
								<NavDropdown.Divider />
								<NavDropdown.Item onClick={() => {
									//const signout = window.confirm("Are you sure you want to signout?");
									//if(signout) {
									auth.signout(() => navigate("/signin", {replace: true})).then();
									//}
								}}>
									Sign out
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default LoggedInNavigation;
