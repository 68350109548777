import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Stack
} from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API from "../../api";
import {Helmet} from "react-helmet";
import {convertToRaw, EditorState, ContentState, convertFromHTML} from "draft-js";
import draftToHtml from "draftjs-to-html";
import CustomFormikRichTextInput from "../../components/WysiwygInput";

const EditFAQ = () => {
	const navigate = useNavigate();
	let { id } = useParams();

	const [faq, setFaq] = useState([])
	const [categories, setCategories] = useState([])
	const [isLoading, setLoading] = useState(false)

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	useEffect(() => {
		setLoading(true);
		API.get(`admin/faq/${id}`)
			.then(response => setFaq(response.data))
			.finally(() => setLoading(false));
		API.get(`admin/faq/categories`)
			.then(response => setCategories(response.data))
			.finally(() => setLoading(false));
	}, [])

	const deleteFaq = async (id) => {
		if(window.confirm("Are you sure you want to delete this faq?")) {
			await API.delete(`admin/faq/${id}`);
			success("Successfully deleted faq");
			navigate('/admin/faq');
		}
	}


	function createSelectItems() {
		return categories.map((category,index) => {
			return <option key={index} value={category.id}>{category.title}</option>
		})
	}

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				question: faq.question || "",
				answer: EditorState.createWithContent(
					ContentState.createFromBlockArray(
						convertFromHTML(faq.answer || "")
					)
				),
				categoryId: faq.categoryId,
				orderBy: faq.orderBy || 0,
			}}
			validate={(values) => {
				const errors = {};
				if (!values.answer) {
					errors.answer = "Required";
				}
				if (!values.question) {
					errors.question = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.put(`admin/faq/${faq.id}`, {
						question: values.question,
						answer: draftToHtml(convertToRaw(values.answer.getCurrentContent())),
						categoryId: values.categoryId,
						orderBy: values.orderBy,
					})
						.then((res) => {
							setSubmitting(false);
							success('Successfully updated faq');
							navigate('/admin/faq');
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
				<>
					<Helmet>
						<title>Edit FAQ | MyGolfDNA.com</title>
						<meta name="description" content="edit faq"/>
					</Helmet>
						<Row>
							<Col md={8}>

								<Form noValidate className="mt-5 form-group" onSubmit={handleSubmit}>
									<h3>Edit Faq</h3>
									<Row>
										<Form.Group className="mb-3">
											<Form.Label className="" htmlFor="category">Category</Form.Label>
											<Form.Select
												aria-label="category"
												name="categoryId"
												id="category"
												onChange={handleChange}
												value={values.categoryId}
												isInvalid={errors.categoryId && touched.categoryId}
												isValid={!errors.categoryId && touched.categoryId}
											>
												{createSelectItems()}
											</Form.Select>
										</Form.Group>
										<Col md={12}>
											<Form.Group className="mb-3">
												<Form.Label className="" htmlFor="question">Question</Form.Label>
												<Form.Control
													className=""
													placeholder="Question"
													id="question"
													name="question"
													onChange={handleChange}
													value={values.question}
													isInvalid={errors.question && touched.question}
													isValid={!errors.question && touched.question}
												/>
												<div className="invalid-feedback">{errors.question}</div>
											</Form.Group>
										</Col>
									</Row>
									<Form.Group className="mb-3">
										<Form.Label className="" htmlFor="answer">Answer</Form.Label>
										<CustomFormikRichTextInput
											name="answer"
											onChange={handleChange}
											value={values.answer}
											isInvalid={errors.answer && touched.answer}
											isValid={!errors.answer && touched.answer}
										/>
										<div className="invalid-feedback">{errors.answer}</div>
									</Form.Group>
									<Col md={3}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Order By"
												type="number"
												name="orderBy"
												id="orderBy"
												onChange={handleChange}
												value={values.orderBy}
												isInvalid={errors.orderBy && touched.orderBy}
												isValid={!errors.orderBy && touched.orderBy}
											/>
											<Form.Label className="" htmlFor="title">Order By</Form.Label>
											<div className="invalid-feedback">{errors.orderBy}</div>
										</Form.Group>
									</Col>
									<FormGroup className="mt-5">
										<Stack direction="horizontal" gap={3}>
											<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
												{isSubmitting ? (
													<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
													</>
												) : (
													"Submit"
												)}
											</Button>
											<Button variant="danger" size="lg" onClick={() => deleteFaq(faq.id)}><i className="bi bi-trash"/></Button>
										</Stack>
									</FormGroup>
								</Form>
							</Col>
						</Row>
				</>
			)}
		</Formik>
	);
};

export default EditFAQ;
