import * as React from "react";
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from "react-helmet";
import API from "../../api";
import {useEffect, useState} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function FAQCategoryList() {
	let navigate = useNavigate();

	const [categories, setCategories] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get("admin/faq/categories")
			.then(response => setCategories(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		navigate(`/admin/faq/category/${event.data.id}`)
	};

	return (
		<>
			<Helmet>
				<title>Faq Categories | MyGolfDNA.com</title>
				<meta name="description" content="FAQ Categories"/>
			</Helmet>
			<div className="mt-4">
				<Row>
					<Col>
						<h2>FAQ Categories</h2>
						<div className="card">
							<DataTable
								value={categories}
								paginator rows={10}
								rowsPerPageOptions={[10, 25, 50]}
								tableStyle={{ minWidth: '50rem' }}
								selectionMode="single"
								onRowSelect={onRowSelect}
							>
								<Column field="title" header="Title"/>
								<Column field="description" header="Description"/>
							</DataTable>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default FAQCategoryList;
