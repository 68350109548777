import * as React from "react";
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from "react-helmet";
import API from "../../api";
import {useEffect, useState} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function FAQList() {
	let navigate = useNavigate();

	const [faqs, setFaqs] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get("admin/faqs")
			.then(response => setFaqs(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		navigate(`/admin/faq/${event.data.id}`)
	};

	return (
		<>
			<Helmet>
				<title>Faqs | MyGolfDNA.com</title>
				<meta name="description" content="FAQs"/>
			</Helmet>
			<div className="mt-4">
				<Row>
					<Col>
						<h2>FAQs</h2>
						<div className="card">
							<DataTable
								value={faqs}
								paginator rows={10}
								rowsPerPageOptions={[10, 25, 50]}
								tableStyle={{ minWidth: '50rem' }}
								selectionMode="single"
								onRowSelect={onRowSelect}
							>
								<Column field="category.title" header="Category"/>
								<Column field="question" header="Question"/>
								<Column field="answer" header="Answer"/>
							</DataTable>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default FAQList;
