import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Stack
} from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API from "../../api";
import {Helmet} from "react-helmet";

const EditUser = () => {
	const navigate = useNavigate();
	let { userId } = useParams();

	const [user, setUser] = useState([])
	const [isLoading, setLoading] = useState(false)

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	useEffect(() => {
		setLoading(true);
		API.get(`admin/user/${userId}`)
			.then(response => setUser(response.data))
			.finally(() => setLoading(false));
	}, [])


	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				firstName: user.firstName || "",
				lastName: user.lastName || "",
				email: user.email || "",
				active: user.active || "true",
				admin: user.admin || "false",
				isInstructor: user.isInstructor || "false",
				instructorConsultLink: user.instructorConsultLink,
				lessonCount: user.lessonCount || 0,
				vimeoId: user.vimeoId || '',
			}}
			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Required";
				}
				if (!values.firstName) {
					errors.firstName = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.put(`admin/user/${user.id}`, {
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						active: values.active,
						admin: values.admin || false,
						isInstructor: values.isInstructor || false,
						instructorConsultLink: values.instructorConsultLink,
						lessonCount: values.lessonCount || 0,
						vimeoId: values.vimeoId || '',
					})
						.then((res) => {
							setSubmitting(false);
							success('Successfully updated user');
							navigate(`/admin/users/${user.id}`);
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
				<>
					<Helmet>
						<title>Edit User | MyGolfDNA.com</title>
						<meta name="description" content="edit user for website"/>
					</Helmet>
					<Row>
						<Col md={8}>

							<Form noValidate className="mt-3 form-group" onSubmit={handleSubmit}>
								<h3>Edit User</h3>
								<Row>
									<Col md={3}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="First Name"
												type="text"
												name="firstName"
												id="firstName"
												onChange={handleChange}
												value={values.firstName}
												isInvalid={errors.firstName && touched.firstName}
												isValid={!errors.firstName && touched.firstName}
											/>
											<Form.Label className="" htmlFor="firstName">First Name</Form.Label>
											<div className="invalid-feedback">{errors.firstName}</div>
										</Form.Group>
									</Col>
									<Col md={3}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Last Name"
												type="text"
												name="lastName"
												id="lastName"
												onChange={handleChange}
												value={values.lastName}
												isInvalid={errors.lastName && touched.lastName}
												isValid={!errors.lastName && touched.lastName}
											/>
											<Form.Label className="" htmlFor="lastName">Last Name</Form.Label>
											<div className="invalid-feedback">{errors.lastName}</div>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={3}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Instructor Vimeo ID"
												type="text"
												name="vimeoId"
												id="vimeoId"
												onChange={handleChange}
												value={values.vimeoId}
												isInvalid={errors.vimeoId && touched.vimeoId}
												isValid={!errors.vimeoId && touched.vimeoId}
											/>
											<Form.Label className="" htmlFor="firstName">Instructor Vimeo ID</Form.Label>
											<div className="invalid-feedback">{errors.vimeoId}</div>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={3}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Instructor Consult Link"
												type="text"
												name="instructorConsultLink"
												id="instructorConsultLink"
												onChange={handleChange}
												value={values.instructorConsultLink}
												isInvalid={errors.instructorConsultLink && touched.instructorConsultLink}
												isValid={!errors.instructorConsultLink && touched.instructorConsultLink}
											/>
											<Form.Label className="" htmlFor="instructorConsultLink">InstructorConsult Link</Form.Label>
											<div className="invalid-feedback">{errors.instructorConsultLink}</div>
										</Form.Group>
									</Col>
								</Row>
									<Row>
										<Col md={6}>
											<Form.Group className="form-floating mb-3">
												<Form.Control
													className=""
													placeholder="email"
													type="email"
													name="email"
													id="email"
													onChange={handleChange}
													value={values.email}
													isInvalid={errors.email && touched.email}
													isValid={!errors.email && touched.email}
												/>
												<Form.Label className="" htmlFor="email">Email</Form.Label>
												<div className="invalid-feedback">{errors.email}</div>
											</Form.Group>
										</Col>
									</Row>
								<Row>
									<Col md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												as="input"
												type="number"
												min="0"
												max="10"
												placeholder="lessonCount"
												name="lessonCount"
												id="lessonCount"
												onChange={handleChange}
												value={values.lessonCount}
												isInvalid={errors.lessonCount && touched.lessonCount}
												isValid={!errors.lessonCount && touched.lessonCount}
											/>
											<Form.Label className="" htmlFor="lessonCount">Addon Lesson Count</Form.Label>
											<small className="text-muted">Additional lessons available on top of plan lessons</small>
											<div className="invalid-feedback">{errors.lessonCount}</div>
										</Form.Group>
									</Col>
								</Row>
									<Col md={3}>
										<Form.Group className="mb-3">
											<Form.Label className="" htmlFor="admin">User Type</Form.Label>
											<Form.Select
												aria-label="admin"
												name="admin"
												id="admin"
												onChange={handleChange}
												value={values.admin}
												isInvalid={errors.admin && touched.admin}
												isValid={!errors.admin && touched.admin}
											>
												<option value="false">User</option>
												<option value="true">Admin</option>
											</Form.Select>
										</Form.Group>
									</Col>
								<Col md={3}>
									<Form.Group className="mb-3">
										<Form.Label className="" htmlFor="admin">Instructor</Form.Label>
										<Form.Select
											aria-label="isInstructor"
											name="isInstructor"
											id="isInstructor"
											onChange={handleChange}
											value={values.isInstructor}
											isInvalid={errors.isInstructor && touched.isInstructor}
											isValid={!errors.isInstructor && touched.isInstructor}
										>
											<option value="false">No</option>
											<option value="true">Yes</option>
										</Form.Select>
									</Form.Group>
								</Col>
									<Col md={3}>
										<Form.Group className="mb-3">
											<Form.Label className="" htmlFor="active">Active</Form.Label>
											<Form.Select
												aria-label="active"
												name="active"
												id="active"
												onChange={handleChange}
												value={values.active}
												isInvalid={errors.active && touched.active}
												isValid={!errors.active && touched.active}
											>
												<option value="false">No</option>
												<option value="true">Yes</option>
											</Form.Select>
										</Form.Group>
									</Col>

								<FormGroup className="mt-5">
									<Stack direction="horizontal" gap={3}>
										<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
											{isSubmitting ? (
												<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
												</>
											) : (
												"Submit"
											)}
										</Button>
									</Stack>
								</FormGroup>
							</Form>
						</Col>
					</Row>
				</>
			)}
		</Formik>
	);
};

export default EditUser;
