import {Col, Container, Row} from "react-bootstrap";

function Footer ({ className }) {
	let textcss = 'd-flex flex-row-reverse';
	if (className === "bg-dark-blue") {
		textcss = 'd-flex flex-row-reverse text-white-50';
	}
	return (
		<>
			<footer className="bg-dark-blue p-4 mt-4" >
				<Container>
					<Row>
						<Col>
							<div className="d-flex text-white-50 p-3">

									<div className="vstack gap-2">
								<h6 className="text-white text-decoration-underline">Help</h6>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="/support">Support Center</a>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="https://shop.mygolfdna.com/pages/contact">Contact Us</a>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="/policies/privacy">Privacy Policy</a>
								</div>

								<div className="vstack gap-2">
									<h6 className="text-white text-decoration-underline">Instruction</h6>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="/pricing">Instructors</a>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="https://meetings.hubspot.com/chris3535/mygolfdna">Pocket Pro</a>
								</div>

								<div className="vstack gap-2">
									<h6 className="text-white text-decoration-underline">Links</h6>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="https://shop.mygolfdna.com/">Shop</a>
									<a className="text-white-50 small" style={{textDecorationLine: "none"}} href="https://blog.mygolfdna.com/">Articles</a>
								</div>
							</div>

						</Col>
					</Row>
					<hr className="text-orange" />
					<Row>
						<Col>
							<div className="text-center text-white-50 pb-3">
								<a className="text-white-50 p-2" target="_blank" href="https://www.instagram.com/mygolfdna/"><i className="bi bi-instagram"></i></a>
								<a className="text-white-50 p-2" target="_blank" href="https://www.youtube.com/@MyGolfDNA"><i className="bi bi-youtube"></i></a>
								<a className="text-white-50 p-2" target="_blank" href="https://www.tiktok.com/@mygolfdna"><i className="bi bi-tiktok"></i></a>
							</div>
							<div className="text-center small text-white-50">© 2025 MyGolfDNA.com</div>
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	)

}

export default Footer;
