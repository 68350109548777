import {useState, useEffect} from "react";
import {useAuth} from "../../AuthUser";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import API from "../../api";
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";

function FAQs() {
	let auth = useAuth();

	const [faqs, setFAQs] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect( () => {
		setLoading(true);
		API.get(`faq`)
			.then(data => setFAQs(data.data))
			.finally(() => setLoading(false));
	}, []);

	return (
		<>
			<Helmet>
				<title>Support Center | MyGolfDNA.com</title>
				<meta name="description" content="Frequently Asked Questions"/>
			</Helmet>
			{isLoading ?
				<div className="p-4" style={{textAlign: 'center'}}>
					<Spinner className="text-orange" animation="border" /></div> :
				<div className="">
					<Container>
						<div className="mt-4">
						<h1 className="text-center monument-font-black">Support Center</h1>
							<p className="text-center text-black-50">Find answers to frequently asked questions below</p>
						</div>
						{faqs?.map(faq => (
							<Row key={faq.id}>
								<Col lg={8}>
									<div className="my-2">
										<h5 className="monument-font text-primary">{faq.title}</h5>
										<p className="text-black-50">{faq.description}</p>
										{faq.faqs.map(f => (
										<Accordion>
											<Accordion.Item eventKey="0">
												<Accordion.Header>{f.question}</Accordion.Header>
												<Accordion.Body>
													{f.answer}
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
										))}
									</div>
								</Col>
							</Row>
						))}
					</Container>
				</div>
			}
		</>
	);
}

export default FAQs;
