import {useAuth} from "../AuthUser";
import {Navigate, useNavigate} from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Button from "react-bootstrap/Button";
import API from "../api";
import {useEffect, useState} from "react";
import {Alert, Modal} from "react-bootstrap";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import LoadingDiv from "../components/LoadingDiv";
import Card from "react-bootstrap/Card";
import {toast} from "react-toastify";

function MyLessons() {
	let auth = useAuth();
	let navigate = useNavigate();

	const canSubmitLesson = auth.user.canSubmitLesson;
	const [lessons, setLessons] = useState([])
	const [lesson, setLesson] = useState([])
	const [user, setUser] = useState([])
	const [isLoading, setLoading] = useState(false)
	const [show, setShow] = useState(false);
	const [result, setResult] = useState();
	const [disabled, setDisabled] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [text, setText] = useState('');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	useEffect(() => {
		setLoading(true);
		API.get("accounts/me")
			.then(response => setUser(response.data));
		API.get("reviews")
			.then(response => setLessons(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onChange = (e) => {
		setText(e.value);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (e.target.notes.value) {
			setSubmitting(true);
			API.put(`reviews/${e.target.lessonId.value}`, {
				notes: e.target.notes.value,
				accountId: e.target.accountId.value,
			})
				.then((response) => {
					navigate('dna-profile');
					success('Successfully updated lesson')
				})
				.finally(() => {
						setSubmitting(false);
						setDisabled(false)
						handleClose()

					}
				)
				.catch(() => {
					setSubmitting(false)
					setDisabled(false)
				})
		}
	}

	const handleDelete = async (e,lessonId) => {
		e.preventDefault()
		if(window.confirm("Are you sure you want to delete this lesson? It cannot be undone and you will lose your line in the queue.")) {
			await API.delete(`reviews/${lessonId}`);
			success("Successfully deleted lesson");
			handleClose();
			navigate('dna-profile');
		}
	}

	const onRowSelect = (event) => {
		if (event.data.status === "complete") {
			navigate(`/lesson/${event.data.id}`)
		}
		if (event.data.status === "pending") {
			setShow(true);
			setLesson(event.data);
			setText(event.data.notes)
		}
	};

	const statusTemplate = (lesson) => {
		if (lesson.status === "complete") {
			return <Badge bg="success">Completed</Badge>
		}
			return <Badge bg="danger">Pending</Badge>
	}
	const editTemplate = (lesson) => {
		if (lesson.status === "complete") {
			return ''
		}
			return <i className="bi bi-pencil-square"/>
	}

	const downloadLinkTemplate = (lesson) => {
		return lesson.reviewVideos.map(findInstructorLesson);
	}

	const createdDateTemplate = (video) => {
		return moment.utc(video.created_at, 'YYYY-MM-DD').format('ll');
	}
	const updatedDateTemplate = (video) => {
		return moment.utc(video.updated_at, 'YYYY-MM-DD').format('ll');
	}

	function findInstructorLesson(video, index) {
		if (video.type === "instructor") return 'Watch Now';
	}

	return (
		<>
		<Card className="lesson-card">
			<Card.Header as="h6">
				<div className="d-none d-md-block">
				<div className="hstack gap-sm-3 gap-1">
					<div className="">My Lessons</div>
					<div className="ms-auto small">{user.lessonCountRemaining >= 10 ? 'Unlimited' : user.lessonCountRemaining} Lesson(s) Left</div>
					<div className="vr"/>
					<div className=""><Button href="/upload-videos" variant="success" size="sm" disabled={!canSubmitLesson}>Start New Lesson</Button></div>
				</div>
				</div>
				<div className="d-md-none">
				<div className="hstack gap-5">
					<div className="">
						My Lessons
						<div className="text-black-50" style={{fontSize: 12}}>{user.lessonCountRemaining >= 10 ? 'Unlimited' : user.lessonCountRemaining} Lesson(s) Left</div>
					</div>
					<div className="ms-auto"><Button href="/upload-videos" variant="success" size="sm" disabled={!user.canSubmitLesson}>Start New Lesson</Button></div>
				</div>
				</div>
			</Card.Header>
			<Card.Body style={{padding: 0, height: '300px', overflowY: 'scroll'}}>
				{isLoading ? <LoadingDiv/> : !auth.user.planType && !lessons.length && !user.lessonCountRemaining ?
					<div className="p-3"> <Alert variant="danger">You must be a premium member to submit lessons. <a href="/pricing">GET ACCESS
						NOW</a></Alert></div> : (
						<>
							<DataTable
								style={{fontSize: '14px'}}
								value={lessons}
								rows={3}
								//rowsPerPageOptions={[10, 25, 50]}
								//tableStyle={{minWidth: '50rem'}}
								selectionMode="single"
								onRowSelect={onRowSelect}
							>
								<Column field="status" header="Status" body={statusTemplate}/>
								<Column field="created_at" header="Submitted" body={createdDateTemplate}/>
								<Column field="updated_at" header="Updated" body={updatedDateTemplate}/>
								<Column header="" body={editTemplate}/>
							</DataTable>
						</>
					)}
			</Card.Body>
		</Card>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Lesson</Modal.Title>
				</Modal.Header>
				<form className="" onSubmit={handleSubmit}>
				<Modal.Body>
						<div className="">
							<p>Status: {statusTemplate(lesson)}</p>
							<p>Submitted Date: {createdDateTemplate(lesson)}</p>
							<p>Notes For Instructor</p>
							<textarea name="notes" value={text} onChange={onChange} className="form-control" placeholder="Enter your comment..."/>
							<input type="hidden" name="accountId" value={lesson.accountId}/>
							<input type="hidden" name="lessonId" value={lesson.id}/>
						</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={(e) => handleDelete(e,lesson.id)} size="sm" variant="danger">
						Delete Lesson
					</Button>
					<Button size="sm" variant="primary" type="submit" disabled={disabled}>{isSubmitting ? (
						<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
						</>
					) : (
						"Update Lesson"
					)}</Button>
				</Modal.Footer>
			</form>
			</Modal>
		</>
	);
}

export default MyLessons;
