import {useAuth} from "../AuthUser";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import API from "../api";
import {useEffect, useState} from "react";
import moment from "moment";
import LoadingDiv from "../components/LoadingDiv";
import React from 'react';
import LessonComments from "../components/LessonComments";

function Lesson() {
	let auth = useAuth();
	let navigate = useNavigate();
	let { lessonId } = useParams();


	const canSubmitLesson = auth.user.canSubmitLesson;
	const lessonCountRemaining = auth.user.lessonCountRemaining || 0;
	const [lesson, setLesson] = useState([])
	const [isLoading, setLoading] = useState(false)
	const playerRef = React.useRef(null);

	const truncate = (text, max, ending = '…') => text.length > max ? text.substr(0, max - ending.length) + ending : text;

	const deleteLesson = async (lesson) => {
		let text = "Are you sure you want to delete this lesson? It cannot be undone.";
		if (window.confirm(text) === true) {
			await API.delete(`admin/lessons/${lesson.id}`);
			navigate('/admin/lessons');
		}

	}


	const downloadLinkTemplate = (lesson) => {
		return lesson?.reviewVideos?.map(findInstructorLesson).join('');
	}

	const createdDateTemplate = (video) => {
		return moment(video.created_at).format('YYYY-MM-DD');
	}

	function findInstructorLesson(video) {
		if (video.type === "instructor") return video.filename;
	}

	useEffect(() => {
		setLoading(true);
		API.get(`reviews/${lessonId}`)
			.then(response => {
				if (response.data === null) {
					navigate('/dna-profile')
				}
				setLesson(response.data.lesson)
			})
			.finally(() => setLoading(false));
	}, [])

	const statusTemplate = (lesson) => {
		if (lesson.status === "complete") {
			return <Badge bg="success">Completed</Badge>
		}
			return <Badge bg="danger">Pending</Badge>
	}

	const updatedOnDateTemplate = (lesson) => {
		if(lesson.status === "pending") {
			return '-'
		}
		return moment(lesson.updated_at).format('YYYY-MM-DD');
	}


console.log(lesson.lesson);
	return (
		<>
			<Helmet>
				<title>My Lesson | MyGolfDNA.com</title>
				<meta name="description" content="Lesson completed by your instructor"/>
			</Helmet>
				<div className="mt-sm-3 mt-5">
					<Container>
						<Row>
							<Col sm={12} md={12} lg={8} xl={8}>
								{isLoading ? <LoadingDiv/> :
									<div className="video-container" style={{height: 400}}>
									<video width="100%" height="100%" controls >
										<source src={downloadLinkTemplate(lesson)} type="video/mp4"/>
									</video>
									</div>
								}
								<Row>
									<div className="mt-3">
										<h6>Questions and Comments</h6>
										<LessonComments/>
									</div>
								</Row>
							</Col>
							<Col>
								<div className="mt-4">
									{lesson?.recommendedVideos?.map((video, index) => {
										return <a key={index} style={{textDecoration: "none"}} href={`/videos/${video.slug}`} title={video.title}>
											<div className="d-flex flex-row mb-2">
												<div className="d-inline-block px-2">
													<div className={!auth.user.planType && !video.free ? 'img-gradient' : 'position-relative'}>
														<div style={{borderRadius: "4px", width: "150px", minHeight: "84px"}}>
															<img src={video.thumbnail} alt={video.title} style={{borderRadius: "4px", maxWidth: "150px", minHeight: "84px"}}/>
															{!auth.user.planType && !video.free ?
																<div className="position-absolute top-50 start-50 translate-middle" style={{zIndex: 10}}>
																	<i className="fa-solid fa-lock" style={{color: "#ff5b25", fontSize: "1.5rem"}}/>

																</div>
																: ''
															}
															<div className="position-absolute bottom-0 end-0 text-white p-1 font-weight-bold" style={{zIndex: 10, fontSize: 10}}>
																<span className="text-white-50">{!auth.user.planType && !video.free ? ' Premium' : ''}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="d-inline-block align-top px-2"><h6 style={{textWrap: 'wrap', fontSize: "14px"}} className="">{truncate(video.title,60, '...')}</h6>
													<p className="mb-1" style={{fontSize: 12}}>{video.Category.title}</p>
													<p className="text-black-50 mb-0" style={{fontSize: 10}}><i className="bi bi-clock"/> {moment(video.releaseOnDate).fromNow()}</p>
												</div>
											</div>
										</a>
									})}
								</div>
							</Col>
						</Row>
					</Container>
				</div>
		</>
	);
}

export default Lesson;
