import React from "react";
import {useNavigate, Link, Navigate, useLocation} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../AuthUser";
import API from "../api";

const Signup = () => {
	const { setUser } = useAuth();
	const navigate = useNavigate();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	return (
		<Formik
			initialValues={{ email: "", password: ""}}
			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Required";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = "Invalid email address";
				}
				if (!values.password) {
					errors.password = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.post("auth/signup", {
						email: values.email,
						password: values.password,
					})
						.then((res) => {
							setUser(res.data);
							navigate('/dna-profile', { replace: true });
							setSubmitting(false);
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 0);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
				<>
					<Container>
						<Row>
							<Col sm={12} md={12} lg={12} xl={12}>
								<h2 className="text-white mt-0 mt-md-5">Sign up for <u>FREE</u> Now!</h2>
								<Form noValidate className="mt-4 form-group" onSubmit={handleSubmit} autoComplete="off">
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className="bg-dark border-light border-opacity-25 text-light"
											placeholder="Email address*"
											type="email"
											name="email"
											id="emails"
											onChange={handleChange}
											value={values.email}
											isInvalid={errors.email && touched.email}
											isValid={!errors.email && touched.email}
										/>
										<Form.Label className="text-white" htmlFor="emails">Email address</Form.Label>
										<div className="invalid-feedback">{errors.email}</div>
									</Form.Group>
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className="bg-dark border-light border-opacity-25 text-white"
											placeholder="Password*"
											type="password"
											name="password"
											id="passwords"
											onChange={handleChange}
											value={values.password}
											autoComplete="new-password"
											isInvalid={errors.password && touched.password}
										/>
										<Form.Label className="text-white" htmlFor="passwords">Your password</Form.Label>
										<div className="invalid-feedback">{errors.password}</div>
									</Form.Group>
									<FormGroup className="mt-4">
										<div className="d-grid gap-1">
											<Button className="text-white font-weight-bold" variant="secondary" size="lg" type="submit" disabled={isSubmitting}>
												{isSubmitting ? (
													<>
													<span
														className="spinner-border spinner-border-sm text-white"
														role="status"
														aria-hidden={true}
													/>{" "}
													</>
												) : (
													"Sign up for FREE"
												)}
											</Button>
											<Button variant="link" as={Link} to="/signin" size="sm" className="text-light text-opacity-75">Already have an account? Sign in</Button>
										</div>
									</FormGroup>
								</Form>
							</Col>
						</Row>
						<ToastContainer />
					</Container>
				</>
			)}
		</Formik>
	);
};

export default Signup;
